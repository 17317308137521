import { createAction, props } from '@ngrx/store';
import {
	BulkSignUpForActivityReq,
	GetAttendeesForInstanceReq,
	SchoolActivityAttendee,
	SchoolActivityInstance,
	SignUpForActivityReq,
} from '../../../services/school-activity.service';

export enum SchoolActivityAttendeesActionTypes {
	GetAttendeesForInstance = '[SchoolActivityAttendees] GetAttendeesForInstance',
	GetAttendeesForInstanceSuccess = '[SchoolActivityAttendees] GetAttendeesForInstance Success',
	GetAttendeesForInstanceFailure = '[SchoolActivityAttendees] GetAttendeesForInstance Failure',

	RemoveAttendeesAction = '[SchoolActivityAttendees] RemoveAttendeesAction',
	RemoveAttendeesActionSuccess = '[SchoolActivityAttendees] RemoveAttendeesAction Success',
	RemoveAttendeesActionFailure = '[SchoolActivityAttendees] RemoveAttendeesAction Failure',

	RemoveAttendeeAction = '[SchoolActivityAttendees] RemoveAttendeeAction',
	RemoveAttendeeActionSuccess = '[SchoolActivityAttendees] RemoveAttendeeAction Success',
	RemoveAttendeeActionFailure = '[SchoolActivityAttendees] RemoveAttendeeAction Failure',

	SignUpForActivity = '[SchoolActivityAttendees] SignUpForActivity',
	SignUpForActivitySuccess = '[SchoolActivityAttendees] SignUpForActivity Success',
	SignUpForActivityFailure = '[SchoolActivityAttendees] SignUpForActivity Failure',

	BulkSignUpForActivity = '[SchoolActivityAttendees] BulkSignUpForActivity',
	BulkSignUpForActivitySuccess = '[SchoolActivityAttendees] BulkSignUpForActivity Success',
	BulkSignUpForActivityFailure = '[SchoolActivityAttendees] BulkSignUpForActivity Failure',

	BulkSignOutForActivity = '[SchoolActivityAttendees] BulkSignOutForActivity',
	BulkSignOutForActivitySuccess = '[SchoolActivityAttendees] BulkSignOutForActivity Success',
	BulkSignOutForActivityFailure = '[SchoolActivityAttendees] BulkSignOutForActivity Failure',
}

export const GetAttendeesForInstanceAction = createAction(
	SchoolActivityAttendeesActionTypes.GetAttendeesForInstance,
	props<{ req: GetAttendeesForInstanceReq }>()
);

export const GetAttendeesForInstanceSuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.GetAttendeesForInstanceSuccess,
	props<{ attendees: SchoolActivityAttendee[] }>()
);

export const GetAttendeesForInstanceFailureAction = createAction(
	SchoolActivityAttendeesActionTypes.GetAttendeesForInstanceFailure,
	props<{ error: string }>()
);

export const RemoveAttendeesAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeesAction);

export const RemoveAttendeesSuccessAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeesActionSuccess);

export const RemoveAttendeesFailureAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeesActionFailure);

export const RemoveAttendeeAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeeAction, props<{ attendeeId: number }>());

export const RemoveAttendeeActionSuccess = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeeActionSuccess);

export const RemoveAttendeeActionFailure = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeeActionFailure, props<{ error: string }>());

export const SignUpForActivityAction = createAction(
	SchoolActivityAttendeesActionTypes.SignUpForActivity,
	props<{ req: SignUpForActivityReq; instance: SchoolActivityInstance }>()
);

export const SignUpForActivitySuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.SignUpForActivitySuccess,
	props<{ attendee: SchoolActivityAttendee }>()
);

export const SignUpForActivityFailureAction = createAction(SchoolActivityAttendeesActionTypes.SignUpForActivityFailure, props<{ error: string }>());

export const BulkSignUpForActivityAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignUpForActivity,
	props<{ req: BulkSignUpForActivityReq; instance: SchoolActivityInstance }>()
);

export const BulkSignUpForActivitySuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignUpForActivitySuccess,
	props<{ attendees: SchoolActivityAttendee[] }>()
);

export const BulkSignUpForActivityFailureAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignUpForActivityFailure,
	props<{ error: string }>()
);

export const BulkSignOutForActivityAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignOutForActivity,
	props<{ attendeeIds: number[] }>()
);

export const BulkSignOutForActivitySuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignOutForActivitySuccess,
	props<{ attendeeIds: number[] }>()
);

export const BulkSignOutForActivityFailureAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignOutForActivityFailure,
	props<{ error: string }>()
);
