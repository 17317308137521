import { DatePipe } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { DialogFactoryService } from '../../dialog-factory.service';
import { DYNAMIC_DIALOG_OPTIONS, DynamicDialogAction, DynamicDialogData } from '../../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from '../../dynamic-dialog.service';
import { DynamicStylingService } from '../../dynamic-styling.service';
import { MenuDialogComponent } from '../../menu-dialog/menu-dialog.component';
import { FlexPeriod } from '../../services/flex-period.service';
import {
	CreateSchoolActivityInstanceReq,
	InstanceTimes,
	SchoolActivity,
	SchoolActivityInstance,
	SchoolActivityService,
} from '../../services/school-activity.service';
import { SelectDaysComponent } from '../../shared/shared-components/select-days/select-days.component';

@Component({
	selector: 'sp-upcoming',
	templateUrl: './upcoming.component.html',
	styleUrls: ['./upcoming.component.scss'],
	providers: [DatePipe],
})
export class UpcomingComponent implements OnChanges, AfterViewInit {
	@ViewChild('wrapper', { static: false, read: ElementRef }) wrapper: ElementRef | undefined;
	@ViewChild('deleteInstanceDialogBody', { read: TemplateRef, static: false }) deleteInstanceDialogBody: TemplateRef<HTMLElement> | undefined;

	@Input() activity!: SchoolActivity;
	@Input() flexPeriod: FlexPeriod | undefined;
	@Input() pastInstances: SchoolActivityInstance[] = [];
	@Input() upcomingInstances: SchoolActivityInstance[] = [];
	@Input() initialDay: Date | undefined;
	@Input() enableLoadingIndicator = true;
	@Input() enableCreation = false;
	@Input() enableDelete = false;
	@Input() emitInitialSelectedInstance = true;
	@Input() emitOnTabChange = false;
	@Input() height: number | null = null;
	@Input() allowInstanceSelection = true;

	@Output() showDeleteInstance: EventEmitter<boolean> = new EventEmitter<boolean>();

	selectedUpdated = false;
	listHeight = '100%';
	indexHovered = -1;
	loading = true;
	upcomingToggledOn = true;
	contentHeightStr = 'auto';
	sessionsEditable = false;
	deleteInstanceDialogService: DynamicDialogService | undefined;
	tooltipText: string | undefined;
	private selectDaysVisible = false;

	selectedTabIndex = 0;

	constructor(
		public activityService: SchoolActivityService,
		private datePipe: DatePipe,
		private dialogFactoryService: DialogFactoryService,
		private dynamicStylingService: DynamicStylingService,
		public matDialog: MatDialog,
		private cdr: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.height) {
			this.listHeight = `${this.height - 156}px`;
		}
		if (changes.activity?.currentValue && JSON.stringify(changes.activity.currentValue) !== JSON.stringify(changes.activity.previousValue)) {
			this.sessionsEditable =
				!!this.matDialog.openDialogs[0]?.componentInstance?.tabbedContentModal ||
				((this.enableCreation || this.enableDelete) && this.activity.status === 'active');
		}

		if (
			!this.selectedUpdated &&
			this.allowInstanceSelection &&
			changes.upcomingInstances?.currentValue &&
			JSON.stringify(changes.upcomingInstances.currentValue) !== JSON.stringify(changes.upcomingInstances.previousValue)
		) {
			if (changes.upcomingInstances.currentValue.length > 0) {
				this.updateSelectedInstance(false, null);
				this.selectedUpdated = true;
				this.allowInstanceSelection = true;
			}
		}
		if (changes.flexPeriod?.currentValue) {
			this.tooltipText = `This activity already occurs every ${this.flexPeriod?.name}.`;
		}
		this.loading = false;
	}

	ngAfterViewInit(): void {
		this.refreshUI();
	}

	updateSelectedInstance(refreshUI: boolean, instanceId: number | null): void {
		this.loading = true;
		if (instanceId) {
			let instanceToSelect = this.upcomingInstances.find((instance) => instance.id === instanceId);
			if (this.selectedTabIndex === 1 || (this.pastInstances.length && this.activity.status === 'archived')) {
				instanceToSelect = this.pastInstances.find((instance) => instance.id === instanceId);
			}
			if (instanceToSelect && this.emitInitialSelectedInstance) {
				this.selectInstance(instanceToSelect, 0);
			}
		} else {
			let instanceToSelect = this.upcomingInstances[0];
			if (this.selectedTabIndex === 1 || (this.pastInstances.length && this.activity.status === 'archived')) {
				instanceToSelect = this.pastInstances[0];
			}
			if (instanceToSelect && this.emitInitialSelectedInstance) {
				this.selectInstance(instanceToSelect, 0);
			}
		}

		this.showDeleteInstance.emit(this.pastInstances.concat(this.pastInstances).length > 1 || this.activity.state === 'flex_recurring');

		if (refreshUI) {
			this.refreshUI();
		}
	}

	private refreshUI(): void {
		// this is needed to get the correct height of the content so it can be scrollable
		this.contentHeightStr = `${this.matDialog.openDialogs[0]?.componentInstance?.contentHeight - 88}px`;
		this.cdr.detectChanges();
	}

	selectInstance(instance: SchoolActivityInstance, index: number): void {
		this.activityService.RemoveAttendeesFromStore();
		this.activityService.SelectActivityInstance(instance.id);
	}

	private resetListScrollPosition(): void {
		if (this.wrapper) {
			this.wrapper.nativeElement.scrollTop = 0;
		}
	}

	onTabSelect(event: number): void {
		this.selectedTabIndex = event;
		this.upcomingToggledOn = !this.upcomingToggledOn;
		this.resetListScrollPosition();
		let instanceToSelect = undefined;
		if (this.emitOnTabChange) {
			switch (event) {
				case 0: // upcoming
					instanceToSelect = this.upcomingInstances[0];
					break;
				case 1: // past
					instanceToSelect = this.pastInstances[0];
					break;
			}
		}
		if (instanceToSelect) {
			this.activityService.SelectActivityInstance(instanceToSelect.id);
		} else {
			this.activityService.ClearSelectedActivityInstance();
		}
	}

	onOptionsClick(event: MouseEvent, instance: SchoolActivityInstance): void {
		event.stopPropagation();
		const dialogPosition = this.dynamicStylingService.keepViewablePosition(event.clientX, event.clientY, 200, 110);

		const data = {
			items: [
				{
					id: 'delete',
					text: 'Delete Session',
					iconLeftPath: './assets/Delete (Red).svg',
					iconLeftPathHighlighted: './assets/Delete (Red).svg',
				},
			],
		};

		const dialogOptions = {
			position: dialogPosition,
			panelClass: ['menu-dialog-container', 'up-list-dialog'],
			backdropClass: 'invis-backdrop',
			data: data,
		};
		const dialogRef = this.matDialog.open(MenuDialogComponent, dialogOptions);

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.openDeleteInstance(instance);
			}
		});
	}

	private openDeleteInstance(instance: SchoolActivityInstance): void {
		const date = this.datePipe.transform(new Date(instance.start_time), 'MMM d');
		if (this.deleteInstanceDialogBody) {
			const data: DynamicDialogData = {
				headerText: `Delete ${date} session?`,
				displayModalFooter: true,
				showCloseIcon: true,
				primaryButtonLabel: 'Delete Session',
				secondaryButtonLabel: 'Cancel',
				modalBody: this.deleteInstanceDialogBody,
				primaryButtonGradientBackground: '#E32C66,#E32C66',
				secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
				secondaryButtonTextColor: '#7083A0',
				disablePrimaryButton: false,
			};
			this.deleteInstanceDialogService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
			this.deleteInstanceDialogService.closed$.pipe(filter(Boolean)).subscribe((selectedOption: unknown) => {
				const selected = selectedOption as DynamicDialogAction;
				if (selected === 'primary') {
					this.deleteInstance(instance);
				}
			});
		}
	}

	private deleteInstance(instance: SchoolActivityInstance): void {
		if (instance.id > 0) {
			this.activityService.DeleteActivityInstance(instance.id);
		} else if (this.activity.id) {
			this.activityService.CreateActivityInstance(new Date(instance.start_time), new Date(instance.end_time), this.activity.id, 'canceled');
			this.activityService.DeleteActivityInstance(instance.id);
			this.activityService.ClearSelectedActivityInstance();
		}

		if (instance.selected) {
			this.selectedUpdated = false;
			this.allowInstanceSelection = true;
			this.activityService.ClearSelectedActivityInstance();
		}
	}

	openSelectDaysModal(): void {
		if (this.selectDaysVisible) {
			return;
		}
		this.selectDaysVisible = true;

		const selectDialog = this.matDialog.open(SelectDaysComponent, {
			panelClass: 'select-days-flex',
			width: '433px',
			disableClose: true,
			data: {
				flexPeriod: this.flexPeriod,
				instances: this.upcomingInstances,
				state: 'scheduled',
			},
		});
		selectDialog.componentInstance.closeDialog = (state: string, times: InstanceTimes[]) => {
			selectDialog.close();
			this.selectDaysVisible = false;
			if (state === 'scheduled') {
				const req: CreateSchoolActivityInstanceReq[] = times.map((time, index) => {
					return {
						start: time.start,
						end: time.end,
						activityId: this.activity.id ? this.activity.id : 0,
						state: 'scheduled',
						selected: index === 0,
					};
				});
				this.activityService.CreateActivityInstanceBulk(req, false);
			} else if (state === 'flex_recurring' && this.activity.state !== 'flex_recurring') {
				this.activityService.UpdateActivity({ id: this.activity.id, state: 'flex_recurring' });
				this.activity.state = 'flex_recurring';
				this.tooltipText = `This activity already occurs every ${this.flexPeriod?.name}.`;
			}
		};
	}
}
